<template>
  <v-container fluid>
    <v-card>
      <!-- toolbar 1 -->
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Inventory - Item Master Data</v-toolbar-title>
      </v-toolbar>
      <!-- end -->

      <!-- toolbar 2 -->
      <v-toolbar color="secondary" flat dense>
        <v-btn
          class="mr-1"
          outlined
          v-if="docRights && docRights.write"
          color="toolbarIcon"
          text
          to="/inventory/new-item-master-data"
        >
          <v-icon left dark>mdi-plus</v-icon>New
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-search"
          label="Search"
          outlined
          flat
          dense
        ></v-text-field>
      
        <v-btn
          small
          color="primary"
          class="ml-10 white --text"
          right
          @click="getMasterData"
          >search
        </v-btn>
      </v-toolbar>
      <!-- end -->

      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="12" md="12">
            <v-data-table
              dense
              small
              v-if="docRights.read == true"
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="masterData"
              :search="search"
              :options.sync="options"
              :server-items-length="totalItems"
              class="elevation-1"
              :server-search="true"
            >
              <template v-slot:item.ItemName="{ item }">
                <v-btn
                  class="btnLink"
                  color="flatButton"
                  text
                  :to="`/item-master-data/${item.id}`"
                  >{{ item.ItemName }}</v-btn
                >
              </template>

              <template v-slot:item.ItemCode="{ item }">
                <v-btn
                  class="btnLink"
                  color="flatButton"
                  text
                  :to="`/item-master-data/${item.id}`"
                  >{{ item.ItemCode }}</v-btn
                >
              </template>

              <template v-slot:item.salesuom.UomName="{ item }">
                <span>{{ item.salesuom ? item.salesuom.UomName : "-" }}</span>
              </template>

              <template v-slot:item.inventoryuom.UomName="{ item }">
                <span>{{ item.inventoryuom ? item.inventoryuom.UomName : "-" }}</span>
              </template>

              <template v-slot:item.purchaseuom.UomName="{ item }">
                <span>{{ item.purchaseuom ? item.purchaseuom.UomName : "-" }}</span>
              </template>
            </v-data-table>

            <v-alert type="error" v-if="docRights.read == false"
              >You are not authorised to read data</v-alert
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <snackbar ref="snackbar"></snackbar>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    docRights: {},
    masterData: [],
    headers: [
      { text: "Item Code", value: "ItemCode" },
      { text: "Item Name", value: "ItemName" },
      { text: "Purchase UoM", value: "purchaseuom.UomName" },
      { text: "Sales UoM", value: "salesuom.UomName" },
      { text: "Inventory UoM", value: "inventoryuom.UomName" },
      { text: "OnHand", value: "OnHand" },
      { text: "Commited", value: "IsCommited" },
    ],
    options: {
      rowsPerPageOptions: [10, 20, 30],
      itemsPerPageOptions: [10, 20, 30],
      itemsPerPage: 10,
      page: 1,
    },
    totalItems: 0,
  }),
  watch: {
    options: {
      handler() {
        this.getMasterData();
      },
      deep: true,
    },
  },
  methods: {
    getMasterData() {
      const self = this;
      this.loading = true;
      let url = `/item_masterdata?page=${this.options.page}&per_page=${this.options.itemsPerPage}&search=${this.search}`;
      this.$store
        .dispatch("get", url)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.masterData = res.ResponseData.data;
            self.options.page = res.ResponseData.current_page;
            self.options.itemsPerPage = res.ResponseData.per_page;
            self.totalItems = res.ResponseData.total;
            self.loading = false;
          }
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    checkRights() {
      const self = this;
      this.$store
        .dispatch("get", `/authorization/check-if-permitted/3`)
        .then((res) => {
          self.docRights = res;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getMasterData();
    this.checkRights();
  },
};
</script>
